import styled from "@emotion/styled";
import { Grid, TextArea, Icon } from "semantic-ui-react";
import React from "react";
import mq from '@styles/mq'
import theme from "@styles/theme";

export const NpsSurveyGrid = styled(Grid)`
    margin-top: 15px !important;
`

export const NpsSurveyTextArea = styled(TextArea)`
    width: 100%;
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    padding: 7.5px;
    ${mq({
        fontSize:['14px','14px','16px'],
        lineHeight: ['24px','24px','24px']
    })}
    font-weight: light;
    letter-spacing: -0.32px;
    &.error {
        border-color: ${theme.brand.colors.error};
        color: ${theme.brand.colors.error};
    }
    &:focus {
        outline: none !important;
    }
`

export const NpsSurveyStarIcon = styled(({highlighted, disabled, ...others}) => <Icon {...others } />)`
    margin-right: 7px !important;
    cursor: pointer;
    color: ${props => props.disabled ? theme.brand.colors.darkGrey : props.highlighted ? '#ffb300' : 'black'};
`

export const SurveyRatingLabel = styled.span`
    display: inline-block;
    margin-left: 5px;
    vertical-align: sub;
`

export const SurveySuccessIcon = styled(Icon)`
    margin-bottom: 5px !important;
    margin-right: 8px !important;
`

export const SurveyCharacterCount = styled.span`
    &.error {
        color: ${theme.brand.colors.error};
    }
`