import Axios from 'axios'
import { getSurveyQuestionsUrl } from '../utils/ApiUrls';
import { SurveyQuestion } from '@models/Survey/SurveyQuestion';

const getSurveyQuestions = async (apiUrl:string, transactionId: number, surveyId: number) => {
    const url = getSurveyQuestionsUrl(apiUrl, transactionId, surveyId);
    const result = await Axios.get(url);
    
    return result.data;
}

const submitSurveyQuestions = async (apiUrl: string, transactionId: number, surveyId: number, answers: SurveyQuestion[]) => {
    const url = getSurveyQuestionsUrl(apiUrl, transactionId, surveyId);
    const answersModel = answers.map((answer) => {
        return {
            QuestionId: answer.Id,
            Response: answer.Answer ? answer.Answer.toString() : ''
        }
    });

    const result = await Axios.post(url, answersModel);

    return result.data;
}

export const SurveyService = {
    getSurveyQuestions: getSurveyQuestions,
    submitSurveyQuestion: submitSurveyQuestions
}