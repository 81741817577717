import React from 'react'
import { Grid, Responsive } from 'semantic-ui-react'
import { SurveyService } from '@services/SurveyService';
import { SurveyQuestion } from '@models/Survey/SurveyQuestion';
import { H3, H6, H2, H4, Paragraph1, Legal, Paragraph2 } from '@styles/Global.styles';
import { LoadingIcon } from '@components/OrderConfirmation/OrderConfirmation.styles';
import { NpsSurveyGrid, NpsSurveyTextArea, NpsSurveyStarIcon, SurveyRatingLabel, SurveySuccessIcon, SurveyCharacterCount } from './NpsSurvey.styles';
import KpButton from '@elements/KpButton/KpButton';
import theme from '@styles/theme';
import { ErrorMessage } from 'formik';
interface NpsSurveyProps {
    title: string,
    description: string,
    successMessage: string,
    apirUrl: string,
    transactionId: number,
    surveyId: number
}

interface NpsSurveyState {
    questions: SurveyQuestion[],
    loadingSurvey: boolean,
    submittingSurvey: boolean,
    submittedSurvey: boolean,
    userCanProceed: boolean,
    showError: boolean,
    errorMessage: string
}

class NpsSurvey extends React.Component<NpsSurveyProps, NpsSurveyState> {

    constructor(props: NpsSurveyProps) {
        super(props);
        this.state = {
            loadingSurvey: true,
            questions: [{
                Answer: '',
                AnswerType: 0,
                Id: 0,
                Text: '',
                CharacterCount: 0
            }],
            userCanProceed: false,
            submittingSurvey: false,
            submittedSurvey: false,
            showError: false,
            errorMessage: ''
        }
    }

    componentDidMount() {
        this.loadSurveyQuestions();
    }

    loadSurveyQuestions() {
        const { apirUrl, transactionId, surveyId } = this.props;

        if (transactionId & surveyId) {
            SurveyService.getSurveyQuestions(apirUrl, transactionId, surveyId).then((data: any) => {
                if (data.Data.Questions.length > 0 && data.Data.UserCanProceed) {
                    this.setState({
                        loadingSurvey: false,
                        questions: data.Data.Questions,
                        userCanProceed: data.Data.UserCanProceed
                    });
                }
                else {
                    this.setState({
                        loadingSurvey: false,
                        userCanProceed: data.Data.UserCanProceed,
                        errorMessage: data.Message
                    })
                }
            });
        }
        else {
            this.setState({
                loadingSurvey: false,
                userCanProceed: false,
                errorMessage: 'It looks like the survey you have tried to access does not exist.'
            })
        }

    }

    updateCharacterCount = (event: any, answerId: any) => {
        const answerText = event.target.value as string;
        const questions = this.state.questions;

        const question = questions.find((question) => question.Id === answerId);

        if (question) {
            question.CharacterCount = answerText.length
        }

        this.setState({
            questions: questions
        });
    }

    setSurveyAnswer(event: any, questionIndex: number, answer: string | number) {
        let questions = this.state.questions;

        questions[questionIndex].Answer = event.target.value !== undefined ? event.target.value : answer;

        this.setState({
            questions: questions
        });
    }

    submitSurvey() {
        const { apirUrl, transactionId, surveyId } = this.props;

        let error = false;
        let errorText = '';

        this.state.questions.forEach((question) => {
            if (!question.Answer) {
                error = true;
                errorText = 'Please ensure all questions are answered'
            }
            if (question.Answer.toString().length > 499) {
                error = true;
                errorText = 'Answers must be less than 500 characters'
            }
        });

        if (!error) {
            this.setState({
                submittingSurvey: true,
                errorMessage: '',
                showError: false
            })

            SurveyService.submitSurveyQuestion(apirUrl, transactionId, surveyId, this.state.questions).then((response) => {
                this.setState({
                    submittingSurvey: false,
                    submittedSurvey: true,
                    showError: !response.Success,
                    errorMessage: response.Message
                })
            });
        }
        else {
            this.setState({
                errorMessage: errorText,
                showError: error
            })
        }
    }

    render() {
        const { questions, loadingSurvey, userCanProceed, submittingSurvey, submittedSurvey, showError, errorMessage } = this.state;
        const { title, description, successMessage } = this.props;
        const stars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

        return (
            <Grid>
                {
                    loadingSurvey && !submittedSurvey &&
                    <Grid.Column width={16} textAlign='left'>
                        <H3>
                            Loading Survey
                            <LoadingIcon name='circle notch' size='huge' loading />
                        </H3>
                    </Grid.Column>
                }
                {
                    !loadingSurvey && !userCanProceed && !submittedSurvey &&
                    <Grid.Column width={16} textAlign='left'>
                        <H3>
                            <SurveySuccessIcon name='times circle' size='huge' />
                                Oops!
                            </H3>
                        <Paragraph1>
                            {errorMessage}
                        </Paragraph1>
                    </Grid.Column>
                }
                {
                    !loadingSurvey && userCanProceed && questions.length > 0 && !submittedSurvey &&
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <H2>{title}</H2>
                            <H4>{description}</H4>
                            <NpsSurveyGrid>
                                {
                                    questions.map((question, i) => {
                                        const isError = !questions[i].Answer ? '' : questions[i].Answer.toString().length >= 500 ? 'error' : ''
                                        return (
                                            <Grid.Row>
                                                <Grid.Column mobile={16} tablet={6} computer={6}>
                                                    <H6>
                                                        {question.Text}
                                                    </H6>
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={8} computer={6}>
                                                    {
                                                        question.AnswerType === 1 &&
                                                        <div>
                                                            <NpsSurveyTextArea rows="10" value={questions[i].Answer} disabled={submittingSurvey} onChange={(event: any) => this.setSurveyAnswer(event, i, '')}></NpsSurveyTextArea>
                                                            <SurveyCharacterCount className={isError ? 'error' : ''}>{questions[i].Answer ? questions[i].Answer.toString().length : 0}/500</SurveyCharacterCount>
                                                        </div>
                                                    }
                                                    {
                                                        question.AnswerType === 0 &&
                                                        stars.map((star) => {
                                                            return <NpsSurveyStarIcon value={star} onClick={submittingSurvey ? null : (event: any) => this.setSurveyAnswer(event, i, star)} highlighted={question.Answer >= star} disabled={submittingSurvey} name={question.Answer >= star ? 'star' : 'star outline'} size='big' />
                                                        })
                                                    }
                                                    {
                                                        question.AnswerType === 0 && question.Answer &&
                                                        <SurveyRatingLabel>{question.Answer}/10</SurveyRatingLabel>
                                                    }
                                                </Grid.Column>
                                            </Grid.Row>
                                        )
                                    })
                                }
                                <Grid.Row>
                                    <Responsive minWidth={theme.responsive.tablet.minWidth} mobile={6} tablet={6} computer={6} as={Grid.Column}></Responsive>
                                    <Grid.Column mobile={16} tablet={6} computer={6}>
                                        <KpButton loading={submittingSurvey} fullWidth='mobile' onClick={() => this.submitSurvey()} id='submit' buttonType='primary' color={theme.brand.colors.blue}>Submit</KpButton>
                                        {
                                            showError && errorMessage &&
                                            <Paragraph2 color={theme.brand.colors.error}>
                                                <br></br>
                                                {errorMessage}
                                            </Paragraph2>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </NpsSurveyGrid>
                        </Grid.Column>
                    </Grid.Row>
                }
                {
                    submittedSurvey &&
                    <Grid.Column width={16} textAlign='left'>
                        {
                            showError &&
                            <div>
                                <H3><SurveySuccessIcon name='times circle' size='huge' />Oops!</H3>
                                <Paragraph1>
                                    {errorMessage ? errorMessage : 'It looks like there was an error submitting your survey, please try re-loading the page.'}
                                </Paragraph1>
                                <Paragraph1>
                                    You can call us on <a href="tel:0800650111">0800 650 111</a> or email us at <a href="mailto:hello@kiwiplates.nz">hello@kiwiplates.nz</a> quoting order #{this.props.transactionId}
                                </Paragraph1>
                            </div>
                        }
                        {
                            !showError &&
                            <div>
                                <H3><SurveySuccessIcon name='check circle' size='huge' />&nbsp;Thank You!</H3>
                                <Paragraph1>
                                    {successMessage}
                                </Paragraph1>
                                <Paragraph1>
                                    Cheers, <br></br>
                                    The KiwiPlates Team
                                </Paragraph1>
                                <Legal color={theme.brand.colors.darkGrey}>
                                    *The discount code provides 10% off your next purchase, expiring 60 days from your first purchase. Each code can only be redeemed once at kiwiplates.nz or by calling 0800 650 111. Offer is valid on full sales only; cannot be used on: Laybys, Gift Vouchers, Redesigns, Government Issue Upgrades, Branded Euro Plates and Premium Plates. Not valid in conjunction with any other offer.
                                </Legal>
                            </div>
                        }
                    </Grid.Column>

                }
            </Grid>
        );
    }
}

export default NpsSurvey