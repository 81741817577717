import React from "react"
import { graphql } from "gatsby"
import {Layout} from "@components/Layout"
import {SEO, mapToSEO} from '@components/SEO'
import { mapToPageHeader, Banner } from "@components/Banner"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { mapToKiwiVIPVisible, SignupBannerWrapper } from "@components/SignupBanner"
import NpsSurvey from "@components/NpsSurvey/NpsSurvey"

interface Props{
  data: any;
  location: any;
}

const queryString = require('query-string');

const loadFromQuerystring = (location: any): {transactionId: number, surveyId: number} =>{
  const query = queryString.parse(location.search,{parseBooleans: true, parseNumbers: true});
  return {
    transactionId: query.id,
    surveyId: query.sid
  };
}

class SurveyLandingPage extends React.Component<Props>{

  render(){ 
    const {elements} = this.props.data.kontentItemSurveyPage;
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const metadata = mapToPageHeader(elements);
    const seoData = mapToSEO(elements);
    const kiwiVip = mapToKiwiVIPVisible(elements);
    const surveyData = loadFromQuerystring(this.props.location);

    return (<Layout version="standard">
                <SEO {...seoData} />
                <Banner {...metadata} isBiggerBanner={false} />
                <GenericBannerContainer 
                  padding={{
                    mobile: {
                        top: 60,
                        bottom: 80
                    },
                    desktop: {
                        top: 80,
                        bottom: 100
                    }
                  }}
                  backgroundColor={theme.brand.colors.beige}>
                    <NpsSurvey title={elements.heading.value} description={elements.description.value} successMessage={elements.thank_you_message.value} apirUrl={apiUrl} transactionId={surveyData.transactionId} surveyId={surveyData.surveyId}></NpsSurvey>
                </GenericBannerContainer>
                {
                    kiwiVip.visible && 
                    <SignupBannerWrapper version= {kiwiVip.version} />
                }
          </Layout>
      );
  }
}

export const query = graphql`{
    site {
      siteMetadata {
        apiUrl
      }
    }
    kontentItemSurveyPage {
      elements {
        description {
          value
        }
        general_page_content__header_image {
          value {
            width
            url
            type
            size
            name
            height
            description
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            codename
            name
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            codename
            name
          }
        }
        general_page_content__title {
          value
        }
        heading {
          value
        }
        general_page_content__title_position {
          value {
            codename
            name
          }
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url {
          value
        }
        thank_you_message {
          value
        }
      }
    }
  }
  
`

export default SurveyLandingPage